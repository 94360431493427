$(function(){
    $('.js-phone').each(function(){
        const input = $(this);
        let maskPattern = '+7 000 000-00-00';
        let maskedInput = IMask(input[0], {
            mask: maskPattern,
            prepare: function (appended, masked) {
                if ( (appended === '8' || appended === '7') && masked.value === '') return '';
                return appended;
            },
        })
        // maskedInput.on('complete', () => console.log('mask completed ', maskedInput.value));
    })

    
    $('.js-date').each(function(){
        const input = $(this);
        let maskedInput = IMask(input[0], {
            mask: Date,
            pattern: 'd.`m.`Y',
            blocks: {
                d: {
                  mask: IMask.MaskedRange,
                  from: 1,
                  to: 31,
                  maxLength: 2,
                },
                m: {
                  mask: IMask.MaskedRange,
                  from: 1,
                  to: 12,
                  maxLength: 2,
                },
                Y: {
                  mask: IMask.MaskedRange,
                  from: 1900,
                  to: 2050,
                }
              },
        })
    })
})