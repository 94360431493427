// if (!!document.querySelector('#calendar')) {
    let curDate = new Date();
    curDate.setHours(0, 0, 0, 0);
    let year = curDate.getFullYear();
    let month = curDate.getMonth();
    
    const favoritesEventsSet = new Set();
    const localStorageKey = 'savedFavoritesEvents';
    const savedFavoritesEvents = localStorage.getItem(localStorageKey) ? JSON.parse(localStorage.getItem(localStorageKey)).filter(Boolean) : [];
    savedFavoritesEvents.forEach((fav) => favoritesEventsSet.add(fav));
    
    /* listener клика по кнопке на странице мероприятия */
    const favBtns = document.querySelectorAll('.js-fav-btn')
    !!favBtns && favBtns.forEach(btn => {
        console.log(favoritesEventsSet)
        if (favoritesEventsSet.has( Number(btn.getAttribute('favorite-status'))) ) {
            btn.classList.add('event__favorites--selected');
        }
        btn.addEventListener('click', toggleSelection)
    })

    /* Отображение списка городов на странице мероприятия */
    const showCitiesBtn = document.querySelector('.js-show-city-list')
    !!showCitiesBtn && showCitiesBtn.addEventListener('click', function() {
        this.remove()
        document.querySelector('.j_extra-cities').classList.remove('is-hidden')
    })
    const closeCitiesBtn = document.querySelector('.js-city-list-close')
    !!closeCitiesBtn && closeCitiesBtn.addEventListener('click', () => {
        document.querySelector('.js-city-list').style.display = 'none'
    })
    
    
    const day = document.querySelector(".calendar__dates");
    const currdateEl = document.querySelector(".calendar__current--date");
    const prenexIcons = document.querySelectorAll(".calendar__header span");
    
    const months = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
    ];
    
    months.forEach((month) => {
        const monthEl = document.createElement('div');
        monthEl.textContent = month;
        monthEl.classList.add('swiper-slide');
        !!currdateEl && currdateEl.appendChild(monthEl);
    });
    
    var calendarDateSelect;
    var calendarDateSelectRender;
    function setSelectValue(valueToSet, isSelected = false) {
        if (!calendarDateSelect || !calendarDateSelectRender) {
            return;
        }
        valueToSet = Number(valueToSet);
        const optionElements = calendarDateSelect.getElementsByTagName('option');
        const values = Array.from(optionElements).map(option => Number(option.value));
        values.push(valueToSet);
        values.sort((a, b) => a - b);
        const uniqueValues = Array.from(new Set(values));
    
        while (calendarDateSelect.firstChild) {
            calendarDateSelect.removeChild(calendarDateSelect.firstChild);
        }
    
        uniqueValues.forEach(value => {
            const optionElement = document.createElement('option');
            optionElement.textContent = value;
            optionElement.value = value;
            calendarDateSelect.appendChild(optionElement);
            if (isSelected && value === valueToSet) {
                calendarDateSelect.value = value;
                optionElement.selected = true;
            }
        });
        if (!isSelected) {
            calendarDateSelect.value = year;
        }
        calendarDateSelectRender.update();
    }
    
    
    const swiperContainer = document.querySelector(".swiper-container");
    var mySwiper;
    var prevScreenSize = undefined;
    var isSwiperFullSize = true;
    
    function swiperNavigationEventFunc() {
        if (this.previousRealIndex == 0 && this.realIndex == 11) {
            year = year - 1;
        } else if (this.previousRealIndex == 11 && this.realIndex == 0) {
            year = year + 1;
        }
        
        if (this.previousRealIndex === month) {
            month = this.realIndex;
        }
    
        setSelectValue(year, true);
        manipulate();
        renderItemList(filterItemsList());
    }
    
    function updateSwiper(size = 1) {
        if (mySwiper) {
            mySwiper.destroy();
        }
        
        mySwiper = new Swiper(swiperContainer, {
            slidesPerView: size,
            slidesPerColumn: size,
            slidesOffsetAfter: 12,
            slidesOffsetBefore: 12,
            centeredSlides: size !== 1,
            simulateTouch: false,
            loop: true,
            initialSlide: month,
            navigation: {
                nextEl: '.next',
                prevEl: '.prev',
            },
        });
    
        mySwiper.on('navigationPrev', swiperNavigationEventFunc);
        mySwiper.on('navigationNext', swiperNavigationEventFunc);
    }
    
    function findSize(isInit = false) {
        const screenWidth = window.innerWidth;
        if (!isSwiperFullSize) {
            updateSwiper(1);
        }
        else if (screenWidth <= 420 && (prevScreenSize > 420 || isInit)) {
            updateSwiper(1);
        }
        else if (screenWidth > 420 && screenWidth < 1500 && (isInit || prevScreenSize >= 1500 || prevScreenSize <= 420)) {
            updateSwiper(3);
        } else if (screenWidth >= 1500 && (isInit || prevScreenSize <= 1500)) {
            updateSwiper(5);
        }
    
        prevScreenSize = screenWidth;
    }
    
    document.addEventListener('DOMContentLoaded', function () {
        if (!document.querySelector('#calendar')) return;

        findSize(true);
        window.addEventListener('resize', () => findSize());
        calendarDateSelect = document.getElementById('date-select');
        calendarDateSelectRender = NiceSelect.bind(calendarDateSelect);
        $(document).on("change", ".calendar--select__year", function () {
            year = +calendarDateSelect.value || +year;
            manipulate();
            renderItemList(filterItemsList());
        });
    
        setSelectValue(year, true);
        manipulate();
    });
    
    var locationsSet = new Set();
    
    if (typeof items !== 'undefined') {
        items.forEach(function (item) {
            item.location.forEach(function (location) {
                locationsSet.add(location);
            });
        })
    }    
    
    const sortedLocations = Array.from(locationsSet).sort();
    
    const selectElement = document.getElementById('location-select');
    
    !!selectElement && selectElement.addEventListener('change', function () {
        filterOptions.location = selectElement.value || null;
        renderItemList(filterItemsList());
    })
    
    sortedLocations.forEach(function (location) {
        const optionElement = document.createElement('option');
        optionElement.textContent = location;
        optionElement.value = location;
        selectElement.appendChild(optionElement);
    });
    
    let calendarShow = false;
    
    const calendarContainer = document.querySelector(".calendar");
    const calendarResize = document.querySelector('.calendar__resize');
    !!calendarResize && calendarResize.addEventListener('click', function () {
        if (calendarShow) {
            calendarResize.innerText = 'Свернуть';
            calendarContainer.classList.toggle('calendar__minimized');
            isSwiperFullSize = true;
            findSize(true);
            calendarDateSelect.disabled = false;
            calendarDateSelectRender.update();
        } else {
            calendarResize.innerText = 'Развернуть';
            calendarContainer.classList.toggle('calendar__minimized');
            isSwiperFullSize = false;
            updateSwiper();
            calendarDateSelect.disabled = true;
            calendarDateSelectRender.update();
        }
        year = curDate.getFullYear();
        month = curDate.getMonth();
        setSelectValue(year, true);
        manipulate();
        renderItemList(filterItemsList());
        calendarShow = !calendarShow;
    })
    
    function manipulate() {
        const dayone = new Date(year, month, 0).getDay();
        const lastdate = new Date(year, month + 1, 0).getDate();
        const dayend = new Date(year, month, lastdate).getDay();
        const monthlastdate = new Date(year, month, 0).getDate();
    
        const createListItem = (metaData, content, className = '', func = null) => {
            const li = document.createElement('li');
            li.textContent = content;
            li.className = className;
            li.setAttribute('date-info', metaData);
    
            const eventsContainer = document.createElement('div');
            eventsContainer.classList.add('calendar__events--container');
            li.appendChild(eventsContainer);
            if (func)
                li.addEventListener('click', func);
            return li;
        };
    
        function handleDateClick(event) {
            day.querySelectorAll('li').forEach((li) => {
                li.classList.remove('active');
            });
            event.target.classList.add('active');
            curDate = new Date(year, month, event.target.textContent);
            manipulate();
            renderItemList(filterItemsList());
        }
    
        function prevChangeMonthDateClick(event) {
            if (month == 0) {
                year = year - 1;
                month = 11;
                setSelectValue(year, true);
            } else {
                month = month - 1;
            }
            curDate = new Date(year, month, event.target.textContent);
            findSize(true);
            setSelectValue(year, true);
            manipulate();
            renderItemList(filterItemsList());
        }
    
        function nextChangeMonthDateClick(event) {
            if (month == 11) {
                year = year + 1;
                month = 0;
                setSelectValue(year, true);
            } else {
                month = month + 1;
            }
            curDate = new Date(year, month, event.target.textContent);
            findSize(true);
            setSelectValue(year, true);
            manipulate();
            renderItemList(filterItemsList());
        }
    
        const fragment = document.createDocumentFragment();
    
        const curDayOfWeek = curDate.getDay();
        let startOfWeek, endOfWeek;
    
        if (curDayOfWeek === 0) {
            startOfWeek = new Date(curDate);
            startOfWeek.setDate(curDate.getDate() - 6);
            endOfWeek = new Date(curDate);
        } else if (curDayOfWeek === 1) {
            startOfWeek = new Date(curDate);
            startOfWeek.setDate(curDate.getDate());
            endOfWeek = new Date(curDate);
            endOfWeek.setDate(curDate.getDate() + 6);
        }
        else {
            startOfWeek = new Date(curDate);
            startOfWeek.setDate(curDate.getDate() - curDayOfWeek + 1);
            endOfWeek = new Date(curDate);
            endOfWeek.setDate(curDate.getDate() + (7 - curDayOfWeek));
        }
        for (let i = dayone; i > 0; i--) {
            const dayNumber = monthlastdate - i + 1;
            const date = new Date(year, month - 1, dayNumber);
            const isToday = date.getDate() === curDate.getDate() && date.getMonth() === curDate.getMonth() && date.getFullYear() === curDate.getFullYear();
            const isSameWeek = date >= startOfWeek && date <= endOfWeek;
            const metaData = `${date.getFullYear()} ${date.getMonth()} ${dayNumber}`;
            fragment.appendChild(createListItem(metaData, dayNumber, `inactive ${isToday ? 'active' : ""} ${isSameWeek ? 'is-same-week' : ''}`, prevChangeMonthDateClick));
        }
    
        for (let i = 1; i <= lastdate; i++) {
            const date = new Date(year, month, i);
            const isToday = date.getDate() === curDate.getDate() && date.getMonth() === curDate.getMonth() && date.getFullYear() === curDate.getFullYear();
    
            const isSameWeek = date >= startOfWeek && date <= endOfWeek;
            const metaData = `${year} ${month} ${i}`;
            fragment.appendChild(createListItem(metaData, i, `${isToday ? 'active' : ''} ${isSameWeek ? 'is-same-week' : ''}`, handleDateClick));
        }
    
        for (let i = dayend; i < 7; i++) {
            const dayNumber = i - dayend + 1;
            const date = new Date(year, month + 1, dayNumber);
            const isToday = date.getDate() === curDate.getDate() && date.getMonth() === curDate.getMonth() && date.getFullYear() === curDate.getFullYear();
            const isSameWeek = date >= startOfWeek && date <= endOfWeek;
            const metaData = `${date.getFullYear()} ${date.getMonth()} ${dayNumber}`;
            fragment.appendChild(createListItem(metaData, dayNumber, `inactive ${isToday ? 'active' : ""} ${isSameWeek ? 'is-same-week' : ''}`, nextChangeMonthDateClick));
        }
    
        const liElements = day.querySelectorAll('li');
    
        liElements?.forEach((node) => {
            node.removeEventListener('click', handleDateClick);
            node.removeEventListener('click', nextChangeMonthDateClick);
            node.removeEventListener('click', prevChangeMonthDateClick)
            day.removeChild(node);
        });
    
        day.appendChild(fragment);
    };
    
    const topicsList = document.querySelector(".calendar__topics--list");
    
    
    if (typeof calendarTopicsList !== 'undefined') {
        Object.entries(calendarTopicsList).forEach(([name, topic]) => {
            const checkbox = document.createElement('input');
            checkbox.type = 'checkbox';
            checkbox.checked = true;
            checkbox.name = name;
        
            const label = document.createElement('label');
            label.textContent = topic.name;
            label.appendChild(checkbox);
        
            const checkMark = document.createElement('span');
            checkMark.style.backgroundColor = `#${topic.color}`
            label.appendChild(checkMark);
        
            topicsList.appendChild(label);
        
            checkbox.addEventListener('change', function () {
                checkMark.style.backgroundColor = this.checked ? `#${topic.color}` : 'transparent';
                filterOptions.types.set(name, checkbox.checked);
                renderItemList(filterItemsList());
            });
        })
    }
    
    function getShortMonthName(dateNumb) {
        const monthNames = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
        return monthNames[dateNumb];
    };
    
    function formatDates(startDate, endDate) {
        const startDay = startDate.getDate();
        const startMonth = startDate.getMonth();
        const startYear = startDate.getFullYear();
        const endDay = endDate.getDate();
        const endMonth = endDate.getMonth();
        const endYear = endDate.getFullYear();
    
        if (startYear === endYear && startMonth === endMonth && startDay === endDay) {
            return `${startDay} ${getShortMonthName(startMonth)}`;
        } else if (startYear === endYear && startMonth === endMonth) {
            return `${startDay}-${endDay} ${getShortMonthName(startMonth)}`;
        } else if (startYear === endYear) {
            return `${startDay} ${getShortMonthName(startMonth)} - ${endDay} ${getShortMonthName(endMonth)}`;
        } else {
            return `${startDay} ${getShortMonthName(startMonth)} ${startYear} - ${endDay} ${getShortMonthName(endMonth)} ${endYear}`;
        }
    };
    
    function toggleSelection() {
        const id = parseInt(this.getAttribute('favorite-status'));
        if (!favoritesEventsSet.has(id)) {
            favoritesEventsSet.add(id);
            this.classList.add('event__favorites--selected');
        } else {
            favoritesEventsSet.delete(id);
            this.classList.remove('event__favorites--selected');
        }
        localStorage.setItem(localStorageKey, JSON.stringify([...favoritesEventsSet]));
    }
    
    const createEventEl = (element) => {
        const period = formatDates(element["date-start"], element["date-end"]);
    
        const listItem = document.createElement("div");
        listItem.classList.add("event");
    
        const periodContainer = document.createElement("div");
        periodContainer.classList.add('period')
        const periodEl = document.createElement("span");
        periodEl.textContent = period;
        periodEl.classList.add('period--text');
        const endDateTime = element["date-end"].getTime();
        if (endDateTime < curDate.getTime()) {
            periodEl.classList.add("period--finished");
        }
        periodContainer.appendChild(periodEl);
        let eventStatus = document.createElement("div");
        eventStatus.classList.add('status');
        eventStatus.textContent = element.online ? 'Онлайн' : 'Офлайн';
        eventStatus.style.backgroundColor = element.online ? 'rgba(231, 12, 65, 1)' : 'rgba(2, 117, 0, 1)';
        periodContainer.appendChild(eventStatus);
        listItem.appendChild(periodContainer);
    
        const titleEl = document.createElement("div");
        titleEl.classList.add('event__title');
        const tittleText = document.createElement("a");
        tittleText.textContent = element.title;
        tittleText.setAttribute('href', element.url)
        /* tittleText.addEventListener('click', function () {
            createModalContent(element);
            modal.style.display = 'flex';
            const page = document.getElementsByClassName('inner-page')[0];
            if (page) {
                page.classList.add('freeze-page');
            }
        }); */
        titleEl.appendChild(tittleText);
        listItem.appendChild(titleEl);
    
        const locationСontainer = document.createElement("div");
        locationСontainer.classList.add('location');
        const locationEl = document.createElement("div");
        locationEl.classList.add('location__city');
        const locationImgIcon = `<svg width="18" height="23" viewBox="0 0 18 23" class="w-use-fill"><use xlink:href="${assetsURL}/images/icons/calendar-map-point.svg#icon"></use></svg>`;
        const locationImg = document.createElement("div");
        locationImg.innerHTML = locationImgIcon;
        locationImg.classList.add('location__icon');
        locationEl.appendChild(locationImg);
        const locationText = document.createElement("span");
        locationText.textContent = `${element.location[0]}`;
        locationEl.appendChild(locationText);
        if (element.location.length > 1) {
            const locationsSize = document.createElement("span");
            locationsSize.textContent = `+${element.location.length - 1}`;
            locationsSize.classList.add('gray-color');
            createModalOfCities(locationEl, locationsSize, element.location);
            locationEl.appendChild(locationsSize);
        }
        locationСontainer.appendChild(locationEl);
        listItem.appendChild(locationСontainer);
    
        const subInfo = document.createElement("div");
        subInfo.classList.add('event__right');
        const typesContainer = document.createElement("div");
        typesContainer.classList.add('event__right--types');
        element.types.forEach((type) => {
            const typeContainer = document.createElement("div");
            typeContainer.classList.add('theme')
            const typeEl = document.createElement("div");
            typeEl.style.backgroundColor = `#${calendarTopicsList[type].color}`;
            const typeName = document.createElement("span");
            typeName.textContent = `${calendarTopicsList[type].name}`;
            typeContainer.appendChild(typeEl);
            typeContainer.appendChild(typeName)
            typesContainer.appendChild(typeContainer);
        })
        subInfo.appendChild(typesContainer);
        eventStatus = document.createElement("div");
        eventStatus.classList.add('status');
        eventStatus.textContent = element.online ? 'Онлайн' : 'Офлайн';
        eventStatus.style.backgroundColor = element.online ? 'rgba(231, 12, 65, 1)' : 'rgba(2, 117, 0, 1)';
        subInfo.appendChild(eventStatus);
    
        const favoriteStatus = document.createElement("div");
        const favoriteStatusIcon = `<svg width="19" height="17" viewBox="0 0 19 17" class=" w-use-fill"><use xlink:href="${assetsURL}/images/icons/calendar-heart.svg#icon"></use></svg>`;
        favoriteStatus.innerHTML = favoriteStatusIcon;
        favoriteStatus.classList.add('event__favorites');
        favoriteStatus.setAttribute('favorite-status', element.id);
        if (favoritesEventsSet.has(element.id)) {
            favoriteStatus.classList.add("event__favorites--selected");
        }
        favoriteStatus.addEventListener('click', toggleSelection);
        subInfo.appendChild(favoriteStatus);
        listItem.appendChild(subInfo);
    
        return listItem;
    }
    
    function filterItemsList() {
        const liElements = day.querySelectorAll('li');
        liElements?.forEach((node) => {
            const typeContainer = node.querySelector('.calendar__events--container');
            while (typeContainer?.firstChild) {
                typeContainer.removeChild(typeContainer.firstChild);
            }
        });
    
        const filteredItems = items.filter(item => {
            if (filterOptions.finished && item["date-end"] >= new Date()) {
                return false;
            }
            if (filterOptions.onGoing && item["date-end"] < new Date()) {
                return false;
            }
            if (filterOptions.onlineOnly && !item.online) {
                return false;
            }
            if (filterOptions.onlyFavorite && !favoritesEventsSet.has(item.id)) {
                return false;
            }
            if (filterOptions.location && !item.location.includes(filterOptions.location)) {
                return false;
            }
            if (filterOptions.types.size > 0) {
                const itemTypes = item.types;
                for (const [type, filter] of filterOptions.types) {
                    if (filter && itemTypes.includes(type)) {
                        return true;
                    }
                }
                return false;
            }
            return true;
        });
    
        return filteredItems;
    };
    
    const onlineSort = document.getElementById("online-sort");
    
    !!onlineSort && onlineSort.addEventListener('change', function () {
        if (onlineSort.checked) {
            onlineSort.parentElement.classList.add('search__checkbox--active');
        } else {
            onlineSort.parentElement.classList.remove('search__checkbox--active')
        }
        filterOptions.onlineOnly = onlineSort.checked;
        renderItemList(filterItemsList());
    })
    
    const favoriteSort = document.getElementById('favorite-sort');
    
    !!favoriteSort && favoriteSort.addEventListener('change', function () {
        if (favoriteSort.checked) {
            favoriteSort.parentElement.classList.add('search__checkbox--active');
        } else {
            favoriteSort.parentElement.classList.remove('search__checkbox--active')
        }
        filterOptions.onlyFavorite = favoriteSort.checked;
        renderItemList(filterItemsList());
    })
    
    const groupedListEl = document.getElementById("grouped__list");
    const tableOption = document.getElementById('table__option');
    const listOption = document.getElementById('list__option');
    
    !!tableOption && tableOption.addEventListener('click', function () {
        tableOption.classList.add('format__option--active');
        listOption.classList.remove('format__option--active');
        groupedListEl.classList.add('events__list');
    });
    
    !!listOption && listOption.addEventListener('click', function () {
        tableOption.classList.remove('format__option--active');
        listOption.classList.add('format__option--active');
        groupedListEl.classList.remove('events__list');
    });
    
    //Фильтрация ближайшие/прошедшие
    var dateItems = document.querySelectorAll('.search__date--item');
    
    dateItems.forEach(function (item, ind) {
        item.addEventListener('click', function () {
            dateItems.forEach(function (el) {
                el.classList.remove('search__date--active');
            });
            item.classList.add('search__date--active');
    
            switch (ind) {
                case 1:
                    filterOptions.onGoing = true;
                    filterOptions.finished = false;
                    break;
                case 2:
                    filterOptions.onGoing = false;
                    filterOptions.finished = true;
                    break;
                default:
                    filterOptions.onGoing = false;
                    filterOptions.finished = false;
            }
    
            renderItemList(filterItemsList());
        });
    
    });
    
    function populateSelect(years) {
        if (!years?.length) {
            return;
        }
    
        years.sort().forEach((yearsItem) => {
            setSelectValue(yearsItem);
        });
    }
    
    const filterText = document.getElementById('filter-text');
    
    const monthsForText = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря"
    ];
    
    function createModalOfCities(locationСontainer, locationsSize, locations) {
        const locationsList = document.createElement("div");
        locationsList.style.display = 'none';
        locationsList.classList.add('location__city--list');
        const locationsListCloseBtn = document.createElement("div");
        locationsListCloseBtn.classList.add('location__city--list--close');
        locationsList.appendChild(locationsListCloseBtn);
        locations.forEach((location)=>{
            const locationsListElement = document.createElement("span");
            locationsListElement.textContent = `${String(location)}`;
            locationsList.appendChild(locationsListElement);
        })
        locationsSize.addEventListener('click', ()=>{
            const locationsModals = [...document.getElementsByClassName('location__city--list')];
            locationsModals?.forEach((el)=>{
                el.style.display = 'none';
            })
            locationsList.style.display = 'grid';
        })
        locationsListCloseBtn.addEventListener('click', ()=>{
            locationsList.style.display = 'none';
        })
        locationСontainer.appendChild(locationsList);
        return locationСontainer;
    }
    
    function renderItemList(items, isInit) {
        if (isInit) {
            var yearsSet = new Set([year]);
        }
        const groupedItems = {};
    
        items.forEach(element => {
            const monthKey = `${element["date-start"].getMonth()} ${element["date-start"].getFullYear()}`;
            if (!groupedItems[monthKey]) {
                groupedItems[monthKey] = [];
            }
            groupedItems[monthKey].push(element);
            if (isInit) {
                yearsSet.add(element["date-start"].getFullYear());
                yearsSet.add(element["date-end"].getFullYear());
            }
        });
    
        if (isInit) {
            populateSelect([...yearsSet]);
        }
    
        while (groupedListEl.firstChild) {
            groupedListEl.removeChild(groupedListEl.firstChild);
        }
    
        if (!items.length) {
            const emptyContent = document.createElement('h1');
            emptyContent.textContent = 'Событий не найдено!';
            emptyContent.style.textAlign = 'center';
            groupedListEl.append(emptyContent);
            return;
        }
    
        let firstDate = curDate;
    
        Object.entries(groupedItems).sort(([dateStr1, _], [dateStr2, __]) => {
            const [day1, year1] = dateStr1.split(' ');
            const [day2, year2] = dateStr2.split(' ');
    
            const date1 = new Date(`${year1}-${day1}`);
            const date2 = new Date(`${year2}-${day2}`);
    
            return date1 - date2;
        }).forEach(([groupPeriod, elements], ind) => {
            const eventContainer = document.createElement("div");
            eventContainer.classList.add('events__list--item');
            groupedListEl.appendChild(eventContainer);
            const [monthNumb, itemYear] = groupPeriod.split(' ');
            const isMonthSelect = curDate.getFullYear() === +itemYear && curDate.getMonth() === +monthNumb;
    
            if (!(ind === 0 && isMonthSelect)) {
                const monthHeader = document.createElement("h6");
                monthHeader.textContent = `${months[monthNumb]} ${itemYear}`;
                const monthHeaderLine = document.createElement('span');
                monthHeaderLine.classList.add('title__line');
                monthHeader.appendChild(monthHeaderLine);
                eventContainer.appendChild(monthHeader);
            };
    
            const uniqueColors = new Map();
            const liElements = day.querySelectorAll('li');
    
            const sortedByDateElements = elements.sort((a, b) => {
                if (a['date-start'] - b['date-start'] !== 0) {
                    return a['date-start'] - b['date-start'];
                }
                return a['date-end'] - b['date-end'];
            });
    
            if (ind === 0 && sortedByDateElements.length) {
                firstDate = sortedByDateElements[0]['date-start'];
            }
            sortedByDateElements.forEach(element => {
                if (+itemYear == year && +monthNumb >= (month - 1) && +monthNumb <= (month + 1)) {
                    liElements?.forEach((node) => {
                        const elDateString = node.getAttribute('date-info');
                        if (!elDateString) {
                            return;
                        }
                        const elDate = new Date(...(elDateString).split(' ').map((val) => parseInt(val, 10)));
    
                        if (isNaN(elDate.getTime())) {
                            return;
                        }
                        if (element['date-start'].getTime() == elDate.getTime()) {
    
                            element.types.filter(type => filterOptions.types.get(type)).forEach((type) => {
                                const existingTypes = uniqueColors.get(elDateString);
                                if (existingTypes) {
                                    if (existingTypes.includes(type)) {
                                        return;
                                    }
    
                                    uniqueColors.set(elDateString, [...existingTypes, type]);
                                } else {
                                    uniqueColors.set(elDateString, [type]);
                                }
                                uniqueColors.set(elDateString, [type]);
                                const eventOnCalendar = document.createElement('div');
                                eventOnCalendar.style.backgroundColor = `#${calendarTopicsList[type].color}`;
                                const typeContainer = node.querySelector('.calendar__events--container');
                                typeContainer.appendChild(eventOnCalendar);
                            })
    
                        }
                    })
                }
                const el = createEventEl(element);
                eventContainer.appendChild(el);
            });
        })
    
        let filteredText = 'Показаны мероприятия в';
        if (filterOptions.location) {
            filteredText += `в регионе "${filterOptions.location}" `;
        } else {
            filteredText += `о всех регионах `;
        }
        const dateMonthText = monthsForText[firstDate.getMonth()];
        const dateYearText = firstDate.getFullYear();
        filteredText += `с ${dateMonthText} ${dateYearText} года `;
        const currentFilterTypes = [...filterOptions.types].filter(([key, val]) => val);
    
        if (filterOptions.types.size !== currentFilterTypes.length) {
            filteredText += `в тематиках: ${currentFilterTypes.map(([key, val]) => calendarTopicsList[key].name).join(', ')}`;
        } else {
            filteredText += 'во всех тематиках';
        }
    
        filterText.innerText = filteredText;
    }
    
    var modal = document.getElementById('modal');
    var closeModalButton = document.getElementById('close__modal');
    var modalContent = document.getElementById('modal__content');
    
    document.addEventListener("DOMContentLoaded", function () {
        if (!document.querySelector('#calendar')) return;
    
        modal.addEventListener('click', function () {
            closeModal();
        });
    
        closeModalButton.addEventListener('click', function () {
            closeModal();
        });
    
        modalContent.addEventListener('click', function (e) {
            e.stopPropagation();
        })
    
        function closeModal() {
            const page = document.getElementsByClassName('inner-page')[0];
            if (page) {
                page.classList.remove('freeze-page');
            }
            modal.style.display = 'none';
        }
        renderItemList(filterItemsList(), true);
    });
    
    
    function createModalContent(content) {
    
        Object.values(modalContent.childNodes).forEach((el) => {
            if (!el?.id) {
                modalContent.removeChild(el);
            }
        });
    
        if (content.logo) {
            const logoEl = document.createElement('img');
            logoEl.classList.add('modal__content--logo')
            logoEl.setAttribute('src', content.logo);
            modalContent.appendChild(logoEl);
        }
    
        const header = document.createElement('h1');
        header.innerText = content.title;
        header.classList.add('modal__content--header')
        modalContent.appendChild(header);
    
        const info = document.createElement('div');
        info.classList.add('modal__content--info');
        const period = formatDates(content["date-start"], content["date-end"]);
        const periodEl = document.createElement("div");
        periodEl.textContent = period;
        periodEl.classList.add('period');
        info.appendChild(periodEl);
    
        const eventStatus = document.createElement("div");
        eventStatus.classList.add('status');
        eventStatus.textContent = content.online ? 'Онлайн' : 'Офлайн';
        eventStatus.style.backgroundColor = content.online ? 'rgba(231, 12, 65, 1)' : 'rgba(2, 117, 0, 1)';
        info.appendChild(eventStatus);
    
        const favoriteStatus = document.createElement("div");
        const favoriteStatusIcon = `<svg width="28" height="24" viewBox="0 0 19 17" class=" w-use-fill"><use xlink:href="${assetsURL}/images/icons/calendar-heart.svg#icon"></use></svg>`;
        favoriteStatus.innerHTML = favoriteStatusIcon;
        favoriteStatus.classList.add('event__favorites');
        favoriteStatus.setAttribute('favorite-status', content.id);
        if (favoritesEventsSet.has(content.id)) {
            favoriteStatus.classList.add("event__favorites--selected");
        }
        favoriteStatus.addEventListener('click', toggleSelection);
        info.appendChild(favoriteStatus);
    
        modalContent.appendChild(info);
    
        const locationСontainer = document.createElement("div");
        locationСontainer.classList.add('modal__content--location', 'location');
        const locationImgIcon = `<svg width="18" height="23" viewBox="0 0 18 23" class="w-use-fill"><use xlink:href="${assetsURL}/images/icons/calendar-map-point.svg#icon"></use></svg>`;
        const locationImg = document.createElement("div");
        locationImg.innerHTML = locationImgIcon;
        locationImg.classList.add('location__icon');
        locationСontainer.appendChild(locationImg);
        const locationText = document.createElement("span");
        locationText.textContent = `${content.location[0] + (content.location[1] ? ', ' + content.location[1] : '')}`;
        locationСontainer.appendChild(locationText);
        if (content.location.length > 2) {
            const locationsSize = document.createElement("span");
            locationsSize.textContent = `+${content.location.length - 1}`;
            locationsSize.classList.add('location__city--accent');
            createModalOfCities(locationСontainer, locationsSize, content.location);
            locationСontainer.appendChild(locationsSize);
        }
        modalContent.appendChild(locationСontainer);
    
        const typesContainer = document.createElement("div");
        typesContainer.classList.add('modal__content--themes');
    
        content.types.forEach((type) => {
            const typeContainer = document.createElement("div");
            const typeEl = document.createElement("div");
            typeEl.style.backgroundColor = `#${calendarTopicsList[type].color}`;
            const typeName = document.createElement("span");
            typeName.textContent = `${calendarTopicsList[type].name}`;
            typeContainer.appendChild(typeEl);
            typeContainer.appendChild(typeName)
            typeContainer.classList.add('theme');
            typesContainer.appendChild(typeContainer);
        })
        modalContent.appendChild(typesContainer);
    
        const button = document.createElement("button");
        button.classList.add('modal__content--button');
        button.innerText = 'Буду участвовать!';
        modalContent.appendChild(button);
    
        const text = document.createElement("p");
        text.classList.add('modal__content--text');
        text.innerText = content?.text || 'Нет описания';
        modalContent.appendChild(text);
    
        if (content?.img) {
            const img = document.createElement('img');
            img.classList.add('modal__content--img');
            img.setAttribute('src', content.img);
            modalContent.appendChild(img);
        }
    }
// }