$(function(){
    const menuObj = {
        dom: {
            burger: $('.js_burger'),
            menuArea: $('.js_menu-area'),
            header: $('.header'),
            root: $('.wrapper'),
            body: $('body'),
        },
        clickHandlers: function() {
            this.dom.burger.on('click', e => {
                $(e.currentTarget).toggleClass('is-active')
                this.dom.menuArea.toggleClass('is-active')
                this.dom.body.toggleClass('no-overflow')
            })
        },
        pageBufferize: function(){
            const headerHeight = this.dom.header.outerHeight()
            this.dom.root.css('padding-top', headerHeight)
        },
        init: function(){
            this.clickHandlers()
            this.pageBufferize()
            window.addEventListener('resize', this.pageBufferize.bind(this))
        }
    }

    menuObj.init()
})