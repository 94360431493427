$(function(){
    const headerSearchInput = $('.js-headerSearch')

    headerSearchInput.on('blur', function() {
        $(this).val() == '' ? $(this).removeClass('not-empty') : $(this).addClass('not-empty')
    })

    $('.search-icon').on('click', function(){
        $(this).closest('form').trigger('submit')
    })
})