$(function(){
    if (!$('#targetedHelp').length) return

    if ($('.js-donation-city-picker').length) {
        let arr = []
        for (let key of donationData) {
            if (!arr.includes(key.city)) {
                arr.push(key.city)
            }
        }
        arr.forEach(city => {
            const option = document.createElement('option')
            option.value = city
            option.innerText = city
            $('.js-donation-city-picker').append(option)
        })
        NiceSelect.bind($('.js-donation-city-picker')[0])
    }

    const area = $('#targetedHelp')
    const itemsNest = area.find('.js-targeted-help-cards')
    let filteredItemsArr = []

    const app = {
        state: {
            city: '',
            status: 'active', // or 'closed'
            currentPageNumber: 1,
            nextIndexFrom: 0,
        },
        itemsToShow: area.data('count'),        
        eventHandlersInit: function(){
            $(document).on('click', '.js-show-more-donation-cards', event => {
                this.renderItems(true)
                this.state.currentPageNumber++

                if (typeof filteredItemsArr[this.state.nextIndexFrom] === 'undefined') event.currentTarget.remove()
            })

            $('[data-donation-status]').on('click', event => {
                const btn = event.currentTarget
                if ($(btn).hasClass('is-active')) return

                this.state.status = btn.dataset.donationStatus
                this.state.currentPageNumber = 1
                this.renderItems()
                $('[data-donation-status]').removeClass('is-active')
                $(btn).addClass('is-active')
            })

            $(document).on('change', '.js-donation-city-picker', event => {
                if (this.state.city == event.currentTarget.value) return

                this.state.city = event.currentTarget.value
                this.state.currentPageNumber = 1
                this.renderItems()
            })
        },
        renderItems: function(isAdding){
            let indexFrom = this.state.currentPageNumber * this.itemsToShow
            if (!isAdding) {
                itemsNest.html('')
                this.deleteShowMoreBtn()
                indexFrom = 0
                filteredItemsArr = []
                this.state.nextIndexFrom = 0
            }
            let indexTo = this.itemsToShow + indexFrom - 1
            
            if (!isAdding) {
                for (let item of donationData) {
                    if (this.state.city !== '') {
                        if (item.status == this.state.status && item.city == this.state.city) {
                            filteredItemsArr.push(item)
                        }
                    }
                    else {
                        if (item.status == this.state.status) {
                            filteredItemsArr.push(item)
                        }
                    }
                }
            }

            $('.no-donation-items-text').remove()
            if (filteredItemsArr.length == 0) {
                area.append('<div class="no-donation-items-text">Ничего не найдено</div>')
            }

            let itemsHTML = ''
            filteredItemsArr.forEach((item, index) => {
                if (index >= indexFrom && index <= indexTo) {
                    const isClosed = item.status == 'closed' ? true : false
                    const classClosed = isClosed ? ' is-closed' : ''
                    const buttonText = isClosed ? 'Сбор закрыт' : 'Помочь'
                    const offset = Math.floor( (item.collectedAmount / item.targetAmount) * 100 )
    
                    itemsHTML += `<div class="targeted-help-card${classClosed}">
                    <div class="targeted-help-card__image-wrapper">
                        <img src="${item.imgURL}" alt="">
                    </div>
                    <div class="targeted-help-card__content">
                        <div class="targeted-help-card__info">
                            <div class="targeted-help-card__title">${item.title}</div>
                            <div class="targeted-help-card__city">${item.city}</div>
                            <div class="targeted-help-card__description">${item.text}</div>
                            <div>
                                <button class="targeted-help-card__read-button js-read-story" data-id="${item.id}">Читать историю</button>
                            </div>
                        </div>
                        <div class="targeted-help-card__bottom">
                            <div class="donation-progress">
                                <div class="donation-progress__numbers">
                                    <div class="donation-progress__number">
                                        <div class="donation-progress__caption">Собрано</div>
                                        <div class="donation-progress__amount">${splitByThousand(item.collectedAmount)} руб</div>
                                    </div>
                                    <div class="donation-progress__number">
                                        <div class="donation-progress__caption">Необходимо</div>
                                        <div class="donation-progress__amount">${splitByThousand(item.targetAmount)} руб</div>
                                    </div>
                                </div>
                                <div class="donation-progress__bar">
                                    <div class="donation-progress__bar-track"></div>
                                    <div class="donation-progress__bar-thumb" style="left:${offset}%;"><span>${offset}%</span></div>
                                </div>
                            </div>
                            <div class="targeted-help-card__buttons">
                                <button class="button button--bordered-s">${buttonText}</button>
                            </div>
                        </div>
                    </div>
                </div>`
                }
            })

            this.state.nextIndexFrom = indexTo + 1
            
            itemsNest.append(itemsHTML)
            if (typeof filteredItemsArr[this.state.nextIndexFrom] !== 'undefined') {
                if (!$('.load-more-button-wrapper').length) this.renderShowMoreBtn()
            }
        },
        deleteShowMoreBtn: function(){
            $('.load-more-button-wrapper').remove()
        },
        renderShowMoreBtn: function() {
            const buttonHTML = `<div class="load-more-button-wrapper">
            <button class="refresh-button js-show-more-donation-cards">
                <img width="17" height="17" class="refresh-button__icon" src="${assetsURL}/images/icons/icon-refresh.svg" alt="more">
                <span class="refresh-button__text">Показать еще</span>
            </button>
        </div>`
            area.append(buttonHTML)
        },

        init: function(){
            this.eventHandlersInit()
            this.renderItems()
        }
    }

    app.init()
})