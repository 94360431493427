$(function(){
    if ($('.share-block').length) {
        function shareBlockPositioning(element) {
            const shareBlock = element
            const shareLinksWrapper = shareBlock.find('.share-block__links')
            const linksList = shareBlock.find('.share-block__links-list')
            const links = linksList.find('a')
            const pl = parseInt(window.getComputedStyle(shareBlock.find('.share-block__links-list')[0]).paddingLeft)
            const gap = parseInt(window.getComputedStyle(shareBlock.find('.share-block__links-list')[0]).gap) * 2 + 2
            let linksWidth = 0
            links.each(function(){
                linksWidth += $(this).outerWidth()
            })
            const width = pl + gap + linksWidth
    
            // code below should be fired on resize also
            shareBlock.css('margin-right', width - pl)
    
            shareBlock.hover( ()=> {
                shareLinksWrapper.css('width', width)
            },
            ()=> {
                shareLinksWrapper.css('width', '0px')
            })
        }
        $('.share-block').each(function(){
            shareBlockPositioning( $(this) )
        })
    
        $(window).on('resize', function(){
            $('.share-block').each(function(){
                shareBlockPositioning( $(this) )
            })
        })
    }

})