$(function(){
    if ($('.js-select').length) {
        if (typeof regions !== 'undefined') {
            const select = $('.js-select[name=region]')
            for (let region of regions) {            
                const option = document.createElement('option')
                option.value = region.id
                option.innerText = region.title
                select.append(option)
            }
        }
        
        $('.js-select').on('change', function(){
            console.log($(this).val())
        })
        
        $('.js-select').each(function(){
            NiceSelect.bind($(this)[0])
        })
    }
})