function pageAutoScroll(pos,speed) {
    var page = $("html, body");
    var speed = speed ? speed : 1000;
    page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function(){
        page.stop();
    });
    page.animate({ scrollTop: pos }, speed, function(){
        page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
    });
}

function splitByThousand(number) {
    if (typeof number !== 'string') number = number.toString()
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}