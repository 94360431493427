$(function(){
    function teamListRender(dpt) {
        const teamListArea = $('.j_teamList')
        
        teamListArea.html('')
        departments.forEach(department => {
            if (department.id == dpt) {
                let employeesHTML = ''
                department.staff.forEach( (employee, index) => {
                    // if (index == 0) {
                    if (false) {
                        const chefImgHtml = employee.photoUrl !== null ? `<img src="${employee.photoUrl}">` : ''
                        teamListArea.append(`
                        <div class="team-cards__main">
                            <div class="team-card-main">
                                <div class="team-card-main__image-wrapper">${chefImgHtml}</div>
                                <div class="team-card-info">
                                <div class="team-card-info__name">${employee.name}</div>
                                <div class="team-card-info__post">${employee.post}</div>
                                    <div class="team-card-info__contact">
                                        <a href="mailto:${employee.email}">${employee.email}</a>
                                    </div>
                                </div>
                            </div>
                        </div>`)
                    }
                    else {
                        employeesHTML += (`<div class="team-card">
                        <div class="team-card-info">
                            <div class="team-card-info__name">${employee.name}</div>
                            <div class="team-card-info__post">${employee.post}</div>
                            <div class="team-card-info__contact">
                                <a href="mailto:${employee.email}">${employee.email}</a>
                            </div>
                        </div>
                    </div>`)
                    }
                })
                
                if (dpt == 'pr') {
                    teamListArea.append(`<div class="team-cards__list team-cards__list--single-col-cards">
                        <div class="team-cards__list-inner">${employeesHTML}</div>
                        <div class="team-cards__bar-area">
                        <div class="bar-w-button-n-icon bar-w-button-n-icon--green">
                            <div class="bar-w-button-n-icon__content">
                                <div class="bar-w-button-n-icon__text">Команда медиапроекта &laquo;Про фудшеринг&raquo;</div>
                                <div class="bar-w-button-n-icon__button-wrapper">
                                    <a href="#" class="button button--green button--centered">Познакомиться на фудшеринг.рф</a>
                                </div>
                            </div>
                            <div class="bar-w-button-n-icon__icon-wrapper">
                                <img src="${assetsURL}/images/icons/icon-man-hand-up.svg">
                            </div>
                        </div>
                    </div>
                    </div>`)
                }
                else {
                    teamListArea.append(`<div class="team-cards__list">${employeesHTML}</div>`)
                }
                
            }
        })
    }

    $('[data-team-department]').on('click', function(){
        teamListRender( $(this).attr('data-team-department') )
        $('[data-team-department]').removeClass('is-active')
        $(this).addClass('is-active')
    })
})