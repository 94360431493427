$(function(){
    const heroSlider = new Swiper('.js-hero-slider', {
      slidesPerView: 1,
      loop: true,
      speed: 500,
      pagination: {
        el: '.js-slider-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.js-slider-arrow-next',
      },
    });

    if ($('.js-awards-slider').length) {
      const sliderEl = $('.js-awards-slider')
      const paginationEl = sliderEl.parent().find('.js-slider-pagination')[0]
      const navNextEl = sliderEl.parent().find('.js-slider-arrow-next')[0]

      const awardsSlider = new Swiper('.js-awards-slider', {
        slidesPerView: 1,
        loop: true,
        speed: 500,
        pagination: {
          el: paginationEl,
          clickable: true,
        },
        navigation: {
          nextEl: navNextEl,
        },
      });
    }

    if ($('.js-article-slider').length) {
      const articleSliderElement = $('.js-article-slider')[0]
      const articleSliderThumbsElement = $('.js-article-thumbs-slider').length ? $('.js-article-thumbs-slider')[0] : null
      
      if (!!articleSliderThumbsElement) {
        var articleSliderThumbs = new Swiper(articleSliderThumbsElement, {
          spaceBetween: 6,
          slidesPerView: 4,
          // freeMode: true,
          // watchSlidesProgress: true,
          allowTouchMove: false,
          // loop: true, bug detected - loops only through previous ones
          navigation: {
            prevEl: articleSliderThumbsElement.parentNode.querySelector('.js-slider-arrow-prev'),
            nextEl: articleSliderThumbsElement.parentNode.querySelector('.js-slider-arrow-next'),
          },
          breakpoints: {
            1024: {
              spaceBetween: 11,
            },
            1680: {
              spaceBetween: 20,
            },
          },
        })
      }

      let thumbsObj = null
      if (!!articleSliderThumbsElement) {
        thumbsObj = {
          swiper: articleSliderThumbs,
        }
      }
      
      window.productSlider = new Swiper(articleSliderElement, {
        // effect: 'fade',
        speed: 500,
        // loop: true,
        // fadeEffect: {
        //   crossFade: true
        // },
        thumbs: thumbsObj,
        navigation: {
          prevEl: articleSliderElement.querySelector('.js-slider-arrow-prev'),
          nextEl: articleSliderElement.querySelector('.js-slider-arrow-next'),
          // disabledClass: 'disabled'
        },
      })
    }

    
    if ($('.js-content-slider-gallery').length) {
      const sliderEl = $('.js-content-slider-gallery')
      const paginationEl = sliderEl.parent().find('.js-slider-pagination')[0]
      const navNextEl = sliderEl.parent().find('.js-slider-arrow-next')[0]

      const slider = new Swiper(sliderEl[0], {
        slidesPerView: 1,
        loop: true,
        speed: 500,
        pagination: {
          el: paginationEl,
          clickable: true,
        },
        navigation: {
          nextEl: navNextEl,
        },
      })
    }

    if ($('.js-widget-slider').length) {
      const sliderEl = $('.js-widget-slider')
      let countDelta = 0
      const counterCurrent = sliderEl.find('.js-slider-count-current')
      const counterTotal = sliderEl.find('.js-slider-count-total')

      const slider = new Swiper(sliderEl[0], {
        slidesPerView: 1,
        loop: false,
        speed: 500,
        navigation: {
          prevEl: sliderEl.find('.js-slider-arrow-prev')[0],
          nextEl: sliderEl.find('.js-slider-arrow-next')[0],
        },
        on: {
          init: function(swiper) {
              if (swiper.passedParams.loop) countDelta = 2
              const currentSlideIndex = swiper.realIndex + 1              
              counterCurrent.text(currentSlideIndex.toString())
              counterTotal.text(`/ ${swiper.slides.length - countDelta}`)
          },
          slideChange: function(swiper) {
              const currentSlideIndex = swiper.realIndex + 1
              counterCurrent.text(currentSlideIndex.toString())
          }
        },
      })

    }

})