$(function(){
    const cookieHTMLArea = $('.js-cookie-text')

    if (document.cookie.indexOf('cookieAgreement') == -1) {
        cookieHTMLArea.append(cookiesHTML)
    }

    $('.js-cookie-accept').on('click', function(){
        cookieHTMLArea.remove()
        let date = new Date(Date.now() + 86400e3*30);
        date = date.toUTCString();
        document.cookie = "cookieAgreement=Y; path=/; expires=" + date;
    })
})