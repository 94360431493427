$(function(){
    $('.js-tab-btn').on('click', function(){
        const tagretTabName = $(this).attr('data-tab')

        $(this).siblings().removeClass('is-active')
        $(this).addClass('is-active')

        $('[data-tabname]').removeClass('is-active')
        $('[data-tabname]').each(function(){
            if ($(this).attr('data-tabname') == tagretTabName) {
                $(this).addClass('is-active')
            }
            else {
                $(this).removeClass('is-active')
            }
        })
    })

    $('[data-tab-id]').on('click', function(){
        const btn = $(this)
        const id = btn.attr('data-tab-id')

        btn.addClass('is-active').siblings().removeClass('is-active')
        $(`#${id}`).addClass('is-active').siblings().removeClass('is-active')
    })
})