$(function(){
    $('.accordeon__headline').on('click', function(){
        const button = $(this)
        const element = button.parent()
        const body = element.find('.accordeon__in')
        body.stop().slideToggle()
    })
    $('.js-accordeon-head').on('click', function(){
        const button = $(this)
        const element = button.parent()
        const body = element.find('.js-accordeon-body')

        body.stop().slideToggle(200)
        element.toggleClass('is-active')
    })

    if ($('.js-reveal-text-button').length) {
        $('.js-reveal-text-button').each(function(){
            const btn = $(this)  
            const parent = btn.closest('.js-toggable-text-block')
            const innerBlockWrapper = parent.find('.toggable-text-block__text-wrapper')
            const innerBlockHeight = parent.find('.toggable-text-block__text').outerHeight()
            let maxHeightInitial = window.getComputedStyle(innerBlockWrapper[0]).maxHeight
            parent.attr('data-height', parseInt(maxHeightInitial))

            $(window).on('resize', function(){
                if (!parent.hasClass('is-toggled')) {
                    maxHeightInitial = window.getComputedStyle(innerBlockWrapper[0]).maxHeight
                    parent.attr('data-height', parseInt(maxHeightInitial))
                }
            })

            btn.on('click', function(){
                if (parent.hasClass('is-toggled')) {
                    innerBlockWrapper.css('max-height', innerBlockHeight)
                    innerBlockWrapper.animate(
                        {'max-height': parent.attr('data-height')},
                        200,
                        function() {
                            innerBlockWrapper.removeAttr('style')
                        }
                    )
                    parent.removeClass('is-toggled')
                    btn.text('Развернуть')
                } else {
                    innerBlockWrapper
                    innerBlockWrapper.animate(
                        {'max-height': innerBlockHeight},
                        200,
                        function(){
                            innerBlockWrapper.css('max-height', 'none')
                        }
                    )
                    parent.addClass('is-toggled')
                    btn.text('Свернуть')
                }
            })
        })
    }

    $('.js-video').on('click', function(){
        const block = $(this),
            video = block.find('iframe'),
            extraParam = video.attr('src').indexOf('?') !== -1 ? '&autoplay=1' : '?autoplay=1'

        if (block.hasClass('started')) return
        block.addClass('started')
        video.attr('src', video.attr('src') + extraParam)
    })


    $('[data-items-count]').each(function(){
        const area = $(this)
        const limit = Number(area.attr('data-items-count'))

        area.find('>div').each(function(i,e){
            if (i >= limit) {
                $(e).addClass('is-hidden')
                if (area.find('.button-nav').length) return

                const btn = document.createElement('button')
                btn.classList.add('button-nav')
                btn.innerText = 'Показать еще'
                btn.addEventListener('click', function(){
                    area.find('>div').each((i,e)=>  $(e).removeClass('is-hidden'))
                    $(this).parent().remove()
                })
                const gridItem = document.createElement('div')
                gridItem.classList.add('partners-grid__item')
                gridItem.insertAdjacentElement('beforeend', btn)
                area.append(gridItem)
            }
        })
    })

    $('a[href^="#"]').on('click', function(e){
        e.preventDefault();
        const headerHeight = $('.header').outerHeight()
        const id = $(this).attr('href').split('#')[1]
        if (id == '') return
        const target = $(`[name=${id}]`)
        
        if (!target.length) return
        pageAutoScroll(target.offset().top - headerHeight, 400)
    })

})